@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #d5d5d5; */
  background-image: url("../public/assets/carpet_background-min.webp");
  background-size: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-wrapper {
  display: grid;
  min-height: 100vh;
  align-items: center;
}

.header-container {
  max-width: 100% !important;
  padding: 0 !important;
}

.header-text {
  /* text-align: center;
  margin: 0 auto !important;
  display: inline-block;
  flex: auto; */
  text-align: center;
  margin: 0 auto !important;
  display: flex;
  flex: auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.image-gallery {
  margin-left: auto;
  margin-right: auto;
}

.add-header-padding {
  padding-right: 27px;
}

.footer {
  display: flex;
  height: 70px;
  background-color: #157b9f;
  color: white;
  font-size: 10px;
  align-items: center;
  position: static;
  bottom: 0;
  width: 100%;
  /* padding: 10px; */
}

.contact-dialog {
  display: flex;
  justify-content: center !important;
}

.step-number {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 9px;
  color: white;
}

.step-number-container {
  background: #157b9f;
  border-radius: 60px;
  width: 50px;
  height: 50px;
}

.coming-soon-v1 {
  position: absolute;
  background: #157b9f;
  color: white;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 10px;
}
